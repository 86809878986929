<template>
    <div>
        <va-card>
            <template slot="header">
              <va-icon
                name="fa fa-cogs mr-3"
                color="success"
              />
              <h5 class="mt-0 mb-0">
                Create new resource
              </h5>
            </template>
              <div class="d-flex flex-center">
                  <div class="flex md4 sm6 xs12">
                      <va-input
                      v-model="orderName"
                      required
                      readonly
                      placeholder="Mandatory Field"
                      >
                        <va-icon
                            slot="prepend"
                            color="gray"
                            name="fa fa-lock"
                        />
                      </va-input>

                      <va-input
                      v-model="title"
                      required
                      placeholder="Title"
                      >
                        <va-icon
                            slot="prepend"
                            color="gray"
                            name="fa fa-envelope-o"
                        />
                      </va-input>

                      <va-input
                      v-model="link"
                      required
                      placeholder="Link (ex: https://www.ahajournals.org"
                      >
                        <va-icon
                            slot="prepend"
                            color="gray"
                            name="fa fa-link"
                        />
                      </va-input>

                      <va-card title="Resource Image"  v-show="isSimple">
                          <va-file-upload
                                  class="flex xs12"
                                  :fileTypes="acceptedFiles"
                                  type="single"
                                  v-model="upload"
                          />
                      </va-card>
  
                      <va-checkbox
                        v-model="isSimple"
                        label="Attach Image or PDF File (Optional)"
                        title="Will disable resource link"
                        class="mb-3"
                      />
                      <va-checkbox
                        v-model="useImageAsLink"
                        label="Use Download URL from Image (Optional)"
                        title="Will disable resource link"
                        class="mb-3"
                      />

                      <div class="flex flex-center">
                          <va-button
                            @click="createResource"
                            :disabled="!allRequirementsMet"
                          >
                            <i
                              class="va-icon fa fa-file-audio-o"
                              style="margin-right: 1em;"
                            />  Create Resource
                          </va-button>
                      </div>
                  </div>
                </div>
        </va-card>
    </div>
  </template>
  <script>
  import {debounce} from 'lodash';
  import moment from 'moment';
  import firebase from 'firebase';
  import axios from '@/scripts/interceptor.js'
  import LogCreator from "../../class/LogCreator"
  export default {
    data() {
      return {
        link: "",
        title: "",
        isSimple: false,
        order: 1,
        orderName: "Order: 0",
        upload: [],
        acceptedFiles: [
            //add images format
            'image/jpeg',
            'image/png',
            'application/pdf',
        ],
        loading: false,
        useImageAsLink: false,
      };
    },
    computed: {
      allRequirementsMet(){
        if(!this.isSimple){
          if(this.title && this.link){
            return true;
          }
        }else{
          if(this.title && (this.link  || this.useImageAsLink) && this.upload.length > 0 ){
            return true;
          }
        }
      },
    },
    async mounted() {
      let lastResource = await firebase.firestore().collection('resources').orderBy('order', 'desc').limit(1).get()
      if(lastResource.docs.length > 0){
        this.order = parseInt(lastResource.docs[0].data().order)
        this.orderName = "Order: " + (parseInt(lastResource.docs[0].data().order) + 1)
      }
      else{
        this.order = 1
        this.orderName = "Order: 1"
      }
    },
    methods: {
        validateField(field, isUrl = false){
          if(field.length > 1 || field != ""){
              if(isUrl){
                const regexURL = /(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|$?!:,.]*[A-Z0-9+&@#/%=~_|$]/i;
                if(!regexURL.test(field)){
                  throw new Error(`Oops, looks like you didn't enter a valid URL - (${field})`)
                }
                else{
                  return true;
                }
              }
              else{
                return true;
              }
          }
          else{
           throw new Error(`Field is empty - ${field}`)
          }
        },
       async startTransaction(){
          //In the case of a simple resource, just title and resource url (external link)
          if(!this.isSimple){
            this.validateField(this.title)
            this.validateField(this.order)
            this.validateField(this.link, true)

            return await firebase.firestore().collection('resources').add({
              active: true,
              isResource: true,
              order: (this.order + 1),
              title: this.title,
              URL: this.link,
              createdAt: moment().format(),
              updatedAt: moment().format(),
            }).then((docRef) => {
              this.$swal({
                title: 'Success!',
                text: 'Resource created successfully',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then(() => {
                this.order += 1
                this.orderName = "Order: " + (this.order + 1)
                this.title = ''
                this.link = ''
                return true;
              })
            }).catch((error) => {
              throw new Error(error.message)
            })
          }else{
            //validate image
            let resourceImage = this.upload[0]
            let fileName = resourceImage.name
            this.validateField(this.title)
            this.validateField(this.order)
            this.validateField(fileName)
            if(!this.useImageAsLink)
              this.validateField(this.link, true)

            //remove whitespaces and lowercase image name
            fileName = fileName.replace(/\s/g, '').toLowerCase()
            //save image in storage
            const ref = firebase.storage().ref().child('/resources/images/' + fileName);
            const imageURL = await ref.put(resourceImage).then(async snapshot => {
              return snapshot.ref.getDownloadURL((url) => {
                return url;
              })
            })
            //file is pdf
            let isPDF = resourceImage.type === 'application/pdf' ? true : false
            return await firebase.firestore().collection('resources').add({
              active: true,
              isResource: false,
              order: (this.order + 1),
              title: this.title,
              URL: (isPDF && this.useImageAsLink) ? imageURL : this.link,
              imageRef: '/resources/images/' + fileName,
              createdAt: moment().format(),
              updatedAt: moment().format(),
            }).then((docRef) => {
              this.$swal({
                title: 'Success!',
                text: 'Resource created successfully',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then(() => {
                this.order += 1
                this.orderName = "Order: " + (this.order + 1)
                this.title = ''
                this.link = ''
                return true;
              })
            }).catch((error) => {
              throw new Error(error.message)
            })
          }
       },
       async createResource(){ 
        this.$swal({
          title: 'Are you sure?',
          text: "You are about to create a new resource",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create'
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;
            try {
              await this.startTransaction()
            } catch (error) {
              this.$swal({
                title: 'Oops!',
                text: error.message,
                icon: 'warning',
                confirmButtonText: 'Ok'
              })
            }
          }
        })
      }
    },
}
 </script>
  